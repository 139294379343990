<template>
    <div class="container">
        <div class="col-md-12">
            <h2> {{ labels.title }} </h2>
        </div>
        <div class="row justify-content-center mt-5">
            <div class="col-md-8 h5">
                <label for="selReporte" class=""> {{ labels.reporte }}</label>
		<select v-model="selReporte" v-on:click="f_sel_reporte">
		<option
			v-for="(item, idx) in reportes"
			:key="'codigo-'+idx"
			:value="item.codigo">
			{{item.codigo}}-{{item.nombre}}
		</option>
		</select> &nbsp;&nbsp;
                <label for="selVigencia" class=""> {{ labels.validity }}</label>
                <select id="selVigencia" name="" v-model="selVigencia">
                    <option value="">{{ labels.select }}</option>
                    <option v-for="vigencia in listVigencia" :key="vigencia.vigencia"> {{ vigencia.vigencia }}</option>
                </select>
            </div>
        </div>
        <div class="row col-md-12 mt-4" v-if="showTblRedFilas">
            <div class="col-md-12 d-flex justify-content-end">
                <td>
                    <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="creaRedFila()">
                        <md-icon>add</md-icon>
                    </md-button>
                </td>
            </div>
        </div>
        <div class="row col-md-12 justify-content-center" v-if="showTblRedFilas">
            <md-table md-card md-header v-model="listRedefinicionFila">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell :md-label="labels.niif" class="text-left border">{{ item.niif }}</md-table-cell>
                    <md-table-cell :md-label="labels.description" class="text-left border">{{ item.descripcion }}</md-table-cell>
                    <md-table-cell :md-label="labels.firstLevel" class="text-left border">{{ namePrimerNivel(item.primer_nivel) }}</md-table-cell>
                    <md-table-cell :md-label="labels.secondLevel" class="border">{{ nameSegundoNivel(item.primer_nivel,item.segundo_nivel) }}</md-table-cell>
                    <md-table-cell :md-label="labels.formulacion" class="text-left border">{{ item.formulacion }}</md-table-cell>
                    <md-table-cell :md-label="labels.editar" class="border">
                        <md-button style="background-color: #e5ac00; width: 35px; height: 35px;" class="md-fab md-primary" @click="editRedFila(item.id)">
                            <md-icon>edit</md-icon>
                        </md-button>
                    </md-table-cell>
                    <md-table-cell :md-label="labels.eliminar" class="border">
                        <md-button style="background-color: #cd2838; width: 35px; height: 35px;" class="md-fab md-primary" @click="deleteRedFila(item.id)">
                            <md-icon>delete</md-icon>
                        </md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
        <CreaRedefinicionFila :modCreaRedFila="showCreaRedeFila" :vigenciaSelected="selVigencia" :codReporte="selReporte" @cierraModal="closeModal"></CreaRedefinicionFila>
        <EditaRedefinicionFila :modEditaRedFila="showRedeFila" :dataRedFila="itemRedFila" :vigenciaSel="selVigencia" :codReporte="selReporte" @cierraModal="closeModal"/>
        <InacticaItem :InactivaItem="deletFila" @confirmDelete="confirmDel" @cierraModal="closeModal"/>
    </div>
</template>

<script>
import RedefinicionFila from '../../../services/RedefinicionFila';
import PrimerNivel from '../../../services/PrimerNivel';
import SegundoNivel from '../../../services/SegundoNivel';
import CreaRedefinicionFila from './CreaRedefinicionFila.vue';
import EditaRedefinicionFila from './EditaRedefinicionFila.vue';
import PlanConsolidacion from '../../../services/PlanConsolidacion';
import TipoReporte from  '../../../services/TipoReporte';
import InacticaItem from '../../utils/InacticaItem.vue'

export default {
    name: "RedefinicionFilas",
    props:[''],
    data: () => ({
        listVigencia : [],
        selVigencia : "",
        selReporte : "",
        listRedefinicionFila : [],
        firstLevSel : "",
        listFirstLev : [],
        listSecondLev : [],
        showCreaRedeFila : false,
        showRedeFila : false,
        itemRedFila : [],
        reportes : [],
        showTblRedFilas : false,
        deletFila : false,
        rowId : ''
    }),
    components:{
        CreaRedefinicionFila,
        EditaRedefinicionFila,
        InacticaItem
    },
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : "Redefinición de filas",
                    save : "Guardar",
                    firstLevel : "Primer Nivel",
                    secondLevel : "Segundo Nivel",
                    formulacion : "Formulación",
                    niif : "NIIF",
                    description : "Descripción _____________________________________________________________________",
                    editar : "Editar",
                    eliminar : "Eliminar",
                    validity : "Vigencia",
                    reporte : "Reporte",
                    select : "Seleccione..."
                }
            }else{
                return {
                    title : "Row redefinition",
                    save : "Guardar",
                    firstLevel : "First Level",
                    secondLevel : "Second Level",
                    formulacion : "Formulation",
                    niif : "NIIF",
                    description : "Description _____________________________________________________________________",
                    editar : "Edit",
                    eliminar : "Delete",
                    validity : "Validity",
                    reporte : "Report",
                    select : "Select..."
                }
            }
        }
    },
methods:{
	f_sel_reporte() {
		if	(this.selReporte) {
			RedefinicionFila.getAllRedefinicionFilas(this.selReporte)
			.then((res) => {
				this.listRedefinicionFila = res.data.redefinicionFila
			})
			.catch((err) => {
				console.log(err)
			})
			PrimerNivel.getFirstLevelByReporte(this.selReporte)
			.then((res) => {
				this.listFirstLev = res.data.firstLevByReport
			})
			.catch((err) => {
				console.log(err)
			})
			SegundoNivel.getAllLevelSecond(this.selReporte)
			.then((res) => {
				this.listSecondLev = res.data.segundoNivel
			})
			.catch((err) => console.log(err))
		}

	},
        closeModal(val){
            this.showCreaRedeFila = val
            this.showRedeFila = val
            this.deletFila = val
        },
        namePrimerNivel(idFirstLev){
            // console.log(idFirstLev)

            let name = this.listFirstLev.find((firstLev) => firstLev.primer_nivel == idFirstLev)
            // console.log(name.descripcion)
            return name == undefined ? idFirstLev : name.descripcion
        },
        nameSegundoNivel(idFirstLev,idSecondLev){
            let name = this.listSecondLev.find((secondLev) => secondLev.primer_nivel == idFirstLev && secondLev.segundo_nivel == idSecondLev)
            // console.log(name)
            // return name != undefined ? name.descripcion : ''
            return name != undefined ? name.descripcion : idSecondLev
        },
        creaRedFila(){
            this.showCreaRedeFila = true
        },
        editRedFila(id){
            // console.log(id)
            RedefinicionFila.getAllFilaById(id)
            .then((res) => {
                // console.log(res.data.redefinicionFilaById)
                this.itemRedFila = res.data.redefinicionFilaById
                this.showRedeFila = true
            })
            .catch((err) => {console.log(err)})
        },
        deleteRedFila(id){
            // console.log(id)
            this.rowId = id
            this.deletFila = true
        },
        confirmDel(){
            // console.log('confirm')
            RedefinicionFila.deleteRedefinicionFila(this.rowId)
            .then((res) => {
                this.$toast.success(res.data.message)
                location.reload()
            })
            .catch((err) => console.log(err))
        }
    },
mounted(){
        
	TipoReporte.getAllTipoReporte()
	.then((res) => {
		this.reportes = res.data.typeReport
	})
	.catch((err) => console.log(err))
	.finally(() => {
		this.isLoading = false;
	})
	PlanConsolidacion.getVigenciaPlanCons()
	.then((res) => {
		this.listVigencia = res.data.getVigencia
	})
	.catch((err) => {
		console.log(err)
	})
},
watch: {
        selVigencia(valNew){
            // console.log(valNew)
            if(valNew != ""){
                this.showTblRedFilas = true
            }else{
                this.showTblRedFilas = false
                this.$toast.error('Diligenciar una vigencia')
            }
        }
    }
}
</script>

<style>

</style>
