import Api from '@/services/Api'

export default {
    getConsolidationPlanById(id){
        return Api().get('planConsolidacionById/'+ id)
    },
    updatePlanconsolidacion(form){
        return Api().put('planConsolidacion/update',form)
    },
    getConsolidPlanBySecondId(id){
        return Api().get('getConsolidPlanBySecondId/'+ id)
    },
    getVigenciaPlanCons(){
        return Api().get('planConsolidacion/getVigenciaPlanCons')
    },
    getPlanConsPorCompVige(vigencia,reporte){
        return Api().get('planConsolidacion/getPlanConsPorCompVige/'+ vigencia + '/'+ reporte)
    },
    getPlanConsPorCuentaVige(idCompania,vigencia,reporte){
        return Api().get('planConsolidacion/getPlanConsPorCuentaVige/'+idCompania+'/'+ vigencia + '/'+ reporte)
    },
    createPlanconsolidacion(form){
        return Api().post('planConsolidacion/create',form)
    },
    deletePlanconsolidacion(id){
        return Api().delete('planConsolidacion/delete/'+id)
    },
    reclasificaNiif(form) {
        return Api().post('planConsolidacion/reclasificaNiif',form)
    },
}
