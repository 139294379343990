<template>
    <div class="container" v-if="dataRedFila[0]">
        <md-dialog :md-active.sync="modEditaRedFila" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.title }}
            </md-dialog-title>
            <md-dialog-content>
            <b-row class="">
                <b-col cols="4" class="text-left">
                    <h5>{{labels.niif}}</h5>
                </b-col>
                <b-col cols="8" class="text-left">
                    <md-input type="number" v-model="dataRedFila[0].niif" class="form-control" />
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="4" class="text-left">
                    <h5>{{labels.descripcion}}</h5>
                </b-col>
                <b-col cols="8" class="text-left">
                    <md-input type="text" v-model="dataRedFila[0].descripcion" class="form-control" size="100" />
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="4" class="text-left">
                    <h5>{{labels.primerNivel}}</h5>
                </b-col>
                <b-col cols="8" class="text-left">
                    <md-input type="number" v-model="dataRedFila[0].primer_nivel" class="form-control"/>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="4" class="text-left">
                    <h5>{{labels.segundoNivel}}</h5>
                </b-col>
                <b-col cols="8" class="text-left">
                    <md-input type="number" v-model="dataRedFila[0].segundo_nivel" class="form-control"/>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="4" class="text-left">
                    <h5>{{labels.actual}}</h5>
                </b-col>
                <b-col cols="8" class="text-left">
                    <select v-model="dataRedFila[0].actual" class="form-control">
                        <option value="1"> {{ labels.yes }} </option>
                        <option value="0"> {{ labels.not }} </option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="4" class="text-left">
                    <h5>{{labels.operacion}}</h5>
                </b-col>
                <b-col cols="8" class="text-left">
                    <select v-model="dataRedFila[0].operacion" class="form-control">
                        <option value="+"> {{ labels.sumar }} </option>
                        <option value="-"> {{ labels.restar }} </option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="4" class="text-left">
                    <h5>{{labels.formulacion}}</h5>
                </b-col>
                <b-col cols="8" class="text-left">
                    <md-input type="text" v-model="dataRedFila[0].formulacion" class="form-control" readonly />
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="4" class="text-left">
                    <h5>{{labels.niif}}</h5>
                </b-col>
                <b-col cols="8" class="text-left">
                    <md-field>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item">
                                <select multiple v-model="arrNiif" class="form-control">
                                    <option v-for="niif in listNiif" :value="niif.niif" :key="niif.id">{{ niif.niif }} {{ niif.nameNiif }}</option>
                                </select>
                            </div>
                        </div>
                    </md-field>
                </b-col>
            </b-row>
            
                <md-dialog-actions class="mt-3">
                    <md-button class="md-primary" @click="guardaRedFila">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="closeModal">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>
import PrimerNivel from '../../../services/PrimerNivel';
import SegundoNivel from '../../../services/SegundoNivel';
import PlanConsolidacion from '../../../services/PlanConsolidacion';
import RedefinicionFila from '../../../services/RedefinicionFila';

export default {
    name: 'EditaRedefinicionFila',
    props:['modEditaRedFila','dataRedFila','vigenciaSel','codReporte'],
    data: () => ({
        listFirstLevel : [],
        listSecondLev : [],
        listNiif : [],
        arrNiif : [],
    }),
    computed:{
        labels(){
            if(this.$store.state._language == "es-ES"){
                return {
                    title : "Edita redefinición fila",
                    save : "Guardar",
                    close : "Cerrar",
                    formulacion : "Formulacion:",
                    niif : "Niif:",
                    descripcion : "Descripción:",
                    primerNivel : "Primer nivel:",
                    segundoNivel : "Segundo nivel:",
                    actual : "Actual:",
                    operacion : "Operación:",
                    select : "Seleccione",
                    yes : "SI",
                    not : "NO",
                    sumar : "+",
                    restar : "-",
                }
            }else{
                return {
                    title : "Edit row redefinition",
                    save : "Save",
                    close : "Close",
                    niif : "Niif:",
                    formulacion : "Formulacion:",
                    descripcion : "Description:",
                    primerNivel : "First level:",
                    segundoNivel : "Second level:",
                    actual : "Current:",
                    operacion : "Operation:",
                    select : "Select",
                    yes : "YES",
                    not : "NO",
                    sumar : "+",
                    restar : "-",
                }
            }
        }
    },
    methods:{
        primNivelSel(){
            let valFistLevSel = document.getElementById('idPrimer').value
            SegundoNivel.getAllLevelSecondByLevelFirstByReport(valFistLevSel,this.codReporte)
            .then((res) => {
                // console.log(res.data)
                this.listSecondLev = res.data.secondLevByFirstLev
            })
            .catch((err) => console.log(err))
        },
        guardaRedFila(){
            // console.log('guardaRedFila')
            // console.log(this.dataRedFila)
            let infoFila = {
                'id' : this.dataRedFila[0].id,
                'codReporte' : this.codReporte,
                'primer_nivel' : this.dataRedFila[0].primer_nivel,
                'segundo_nivel' : this.dataRedFila[0].segundo_nivel,
                'niif' : this.dataRedFila[0].niif,
                'descripcion' : this.dataRedFila[0].descripcion,
                'contable' : 0,
                'cuenta' : null,
                'actual' : this.dataRedFila[0].actual,
                'operacion' : this.dataRedFila[0].operacion,
                'formulacion' : this.dataRedFila[0].formulacion,
                'listaNiif' : this.arrNiif,
                'idUser' : this.$store.state.userId
            }

            RedefinicionFila.updateRedefinicionFila(infoFila)
            .then((res) => {
                // console.log(res.data)
                this.$toast.success(res.data.message)
                location.reload()
            })
            .catch((error) => console.log(error))
        },
        closeModal(){
            // console.log('closeModal')
            this.$emit('cierraModal',false)
        }
    },
    mounted(){
	if	(this.codReporte) {
		PrimerNivel.getFirstLevelByReporte(this.codReporte)
		.then((result) => {
			this.listFirstLevel = result.data.firstLevByReport
		})
		.catch((err) => {
			console.log(err)
		})
	}
    },
    watch:{
        modEditaRedFila(valNew){
            // console.log(valNew)
            if(valNew){
                PlanConsolidacion.getPlanConsPorCompVige(this.vigenciaSel,this.codReporte)
                .then((res) => {
                    this.listNiif = res.data.planConsNiif
                })
                .catch((err) => console.log(err))

		SegundoNivel.getAllLevelSecondByLevelFirstByReport(this.dataRedFila[0].primer_nivel,this.codReporte)
                .then((res) => {
                    // console.log(res.data)
                    this.listSecondLev = res.data.secondLevByFirstLev
                })
                .catch((err) => console.log(err))
            }
        }
    }
}
</script>

<style>

</style>
