<template>
    <div class="container">
        <div class="col-md-12">
            <h2>{{ labels.title }}</h2>
        </div>
        <div class="row justify-content-center mt-5">
            <div class="col-md-4 h5">
                <label for="selVigencia" class=""> {{ labels.validity }}</label>
                <select id="selVigencia" name="" v-model="selVigencia" @click="listPlanConsoli=[];fgl_consulta=false">
                    <option value="">{{ labels.select }}</option>
                    <option v-for="vigencia in listVigencia" :key="vigencia.vigencia"> {{ vigencia.vigencia }}</option>
                </select>
            </div>
            <div class="col-md-4 h5">
                <label for="selVigencia" class=""> {{ labels.report }}</label>
                <select id="selVigencia" name="" v-model="selReporte" @click="f_lea_primer_nivel();f_lea_segundo_nivel();listPlanConsoli=[];fgl_consulta=false">
                    <option value="">{{ labels.select }}</option>
                    <option v-for="reporte in listReporte" :key="reporte.id" :value="reporte.codigo"> {{ reporte.nombre }}</option>
                </select>
            </div>
        </div>
        <div class="row justify-content-center mt-3">
            <div class="col-md-12">
                <b-button @click="consultaNiif();fgl_consulta=true" id="consult-btn">{{ labels.consultar }}</b-button>
            </div>
        </div>
        <div v-if="fgl_consulta">
            <div class="row">
                <div class="col-md-3 justify-content-left">
                    <md-field md-inline>
                        <label>{{labels.search}}</label>
                        <md-input v-model="searchNiif" @input="searchOnTableNiif"></md-input>
                    </md-field>
                </div>
                <div class="col-md-9 d-flex justify-content-end">
                    <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="crearNiif()">
                        <md-icon>add</md-icon>
                    </md-button>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-12 mt-4">
                    <table class="table table-hover table-striped table-bordered">
                        <thead>
                            <tr>
                                <!-- <th scope="col">{{labels.validity}}</th> -->
                                <th scope="col">{{labels.niif}}</th>
                                <th scope="col">{{labels.description}}</th>
                                <th scope="col">{{labels.levelFirst}}</th>
                                <th scope="col">{{labels.levelSecond}}</th>
                                <th scope="col">{{labels.edit}}</th>
                                <th scope="col">{{labels.delete}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="planCons in datosPaginados" :key="planCons.id">
                                <!-- <td>{{ planCons.vigencia }}</td> -->
                                <td>{{ planCons.niif }}</td>
                                <td class="text-left">{{ planCons.nameNiif }}</td>
                                <td class="text-left">{{ planCons.namePrimerNivel }}</td>
                                <td class="text-left">{{ planCons.nameSegundoNivel }}</td>
                                <td>
                                    <md-button @click="editaNiif(planCons.id)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
                                </td>
                                <td>
                                    <md-button @click="deleteNiif(planCons.id)" class = "md-raised md-accent"><md-icon>delete</md-icon></md-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#">{{ labels.previous }}</a></li>
                            <li v-for="pagina in totalPaginas()" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                            <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">{{labels.next}}</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div v-else class="mt-4 h4"> No hay datos</div>
        <div v-if="!isLoading"></div>
        <div class="loadingSpinner" :class="{ active: isLoading }">
            <b-spinner></b-spinner>
        </div>
        <Edit :showDialogEditNiif="showDialogEditNiif" @clicked="closeModal" :Item="itemSelected" :listFirstLevel="listFirstLevel" :listSecondLevelFirst="listSecondLevelFirst" @leaSegundoNivel="leaSegundoNivel" :listNiif="listPlanConsoli" @reload="refrescaTabla"></Edit>
        <Crear :showDialogCrearNiif="showDialogCrearNiif" :vigencia="vigenciaSelected" :codReporte="selReporte" :listFirstLevel="listFirstLevel" @clicked="closeModal" @reload="refrescaTabla"/>
        <InacticaItem :InactivaItem="showModDel" @confirmDelete="confirmDel" @cierraModal="closeModal" />
    </div>
</template>
<script>
    import PlanConsolidacion from '../../services/PlanConsolidacion';
    import TipoReporte from '../../services/TipoReporte';
    import PrimerNivel from '../../services/PrimerNivel'
    import SegundoNivel from '../../services/SegundoNivel'
    import InacticaItem from '../utils/InacticaItem.vue';
    import Crear from './Crear.vue';
    import Edit from './Edit.vue';

    const toLower = (text) => {
        return text.toString().toLowerCase();
    };

    const searchByNiif = (items, term) => {
        if (term) {
            return items.filter((item) =>
                toLower(item.nameNiif).includes(toLower(term))
            );
        }
        return items;
    };

    export default {
        name: "Niif",
        data: () => ({
            fgl_consulta: false,
            companyId : "",
            selVigencia : "",
            searchNiif : null,
            listPlanConsoli : [],
            elementosPorPagina:10,
            datosPaginados: [],
            paginaActual: 1,
            showDialogCrearNiif: false,
            showDialogEditNiif: false,
            listFirstLevel : [],
            listSecondLevel : [],
            listSecondLevelFirst : [],
            nuevoNiif : {},
            itemSelected : {},
            listVigencia: [],
            isLoading: false,
            showBtnCrear: false,
            vigenciaSelected: "",
            selReporte : "",
            listReporte : [],
            showModDel : false,
            rowDelete : null
            // listCompany: []
        }),
        components: {
            Edit,
            Crear,
            InacticaItem
        },
        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "NIIF",
                        select: "Seleccione...",
                        search: "Buscar",
                        create: "Crear",
                        niif : "Niif",
                        company : "Comapañía",
                        validity : "Vigencia: ",
                        report : "Reporte: ",
                        description : "Descripción",
                        levelFirst : "Primer Nivel",
                        levelSecond : "Segundo Nivel",
                        consultar : "Consultar",
                        edit : "Editar",
                        delete : "Eliminar",
                        previous: "Ant.",
                        next: "Sig.",
                    }
                }else{
                    return {
                        title: "NIIF",
                        select: "Select...",
                        search: "Search",
                        create: "Create",
                        niif : "Niif",
                        company : "Company",
                        validity : "Validity: ",
                        report : "Report: ",
                        description : "Description",
                        levelFirst : "Level first",
                        levelSecond : "Level second",
                        consultar : "Consult",
                        edit : "Edit",
                        delete : "Delete",
                        previous: "Previous",
                        next: "Next",
                    }
                }
            }
        },
methods: {
	f_lea_primer_nivel() {
		PrimerNivel.getFirstLevelByReporte(this.selReporte)
		.then((r_primerNivel) => {
			this.listFirstLevel=r_primerNivel.data.firstLevByReport
		})
	},
	f_lea_segundo_nivel() {
		SegundoNivel.getAllLevelSecond(this.selReporte)
		.then((r_segundoNivel) => {
			this.listSecondLevel=r_segundoNivel.data.segundoNivel
		})
	},
	consultaNiif(){
		this.isLoading = true;
		this.showBtnCrear = true;

		PlanConsolidacion.getPlanConsPorCompVige(this.selVigencia,this.selReporte)
		.then((res) => {
			this.listPlanConsoli = res.data.planConsNiif
			this.getDataPagina(1);
		})
		.catch((err) => {
			console.log(err)
		})
		.finally(() => {
			this.isLoading = false;
		})

	},
	crearNiif(){
		this.showDialogCrearNiif = true
		this.vigenciaSelected = this.selVigencia
	},
	editaNiif(id){
		this.itemSelected = this.datosPaginados.find((d_niif) => d_niif.id===id)
		this.itemSelected.descripcion = this.itemSelected.nameNiif
		this.itemSelected.codReporte = this.selReporte
		this.itemSelected.vigencia = this.selVigencia
		this.itemSelected.niif_nuevo = this.itemSelected.niif

		this.listSecondLevelFirst = this.listSecondLevel.filter((d_second) => d_second.primer_nivel == this.itemSelected.primer_nivel)
		this.showDialogEditNiif = true
	},
	leaSegundoNivel(p_primer_nivel) {
		const SecondLevelFirst = this.listSecondLevel.filter((d_second) => d_second.primer_nivel == p_primer_nivel)
		this.listSecondLevelFirst = SecondLevelFirst
	},
            deleteNiif(id){
                this.rowDelete = id
                this.showModDel = true
            },
            confirmDel(){
                PlanConsolidacion.deletePlanconsolidacion(this.rowDelete)
                .then((res) => {
                    this.$toast.success(res.data.message)
                    this.refrescaTabla(this.selVigencia,this.selReporte)
                    this.showModDel = false
                })
                .catch((err) => console.log(err))
            },
            closeModal(value){
                this.showDialogEditNiif = value
                this.showDialogCrearNiif = value
                this.showModDel = value
            },
            refrescaTabla(){
                // console.log(vigencia,"EEE")
                this.isLoading = true
		this.consultaNiif()
		this.fgl_consulta=true 
            },
            searchOnTableNiif(){
                this.datosPaginados = searchByNiif(this.listPlanConsoli, this.searchNiif);
                if(this.searchNiif == ''){
                    this.getDataPagina(1)
                }
            },
            totalPaginas(){
                return Math.ceil(this.listPlanConsoli.length / this.elementosPorPagina)
            },
            getDataPagina(noPagina){
                this.datosPaginados = [];
                this.paginaActual = noPagina;
                let ini = (noPagina * this.elementosPorPagina) - this.elementosPorPagina;
                let fin = (noPagina * this.elementosPorPagina);

                this.datosPaginados = this.listPlanConsoli.slice(ini,fin);
            },
            getPreviousPage(){
                if(this.paginaActual > 1){
                    this.paginaActual--;
                }
                this.getDataPagina(this.paginaActual);
            },
            getNextPage(){
                if(this.paginaActual < this.totalPaginas()){
                    this.paginaActual++;
                }
                this.getDataPagina(this.paginaActual);
            },
                isActive(noPagina){
                return noPagina == this.paginaActual ? 'active':'';
            },
        },
        mounted(){
            this.isLoading = true;

            PlanConsolidacion.getVigenciaPlanCons()
            .then((res) => {
                this.listVigencia = res.data.getVigencia
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                this.isLoading = false;
            })

            TipoReporte.getAllTipoReporte()
            .then((res) => {
                this.listReporte = res.data.typeReport
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                this.isLoading = false;
            })
        }
    }
</script>
<style src="../main.css"></style>
