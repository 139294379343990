import Api from '@/services/Api'

export default {
    getFirstLevelByReporte(reporte){
        return Api().get('getFirstLevelByReporte/'+ reporte)
    },
    createLevelFirst(form){
        return Api().post('levelFirst/create',form)
    },
    updatePrimerNivel(form){
        return Api().post('levelFirst/update',form)
    }

}
