<template>
    <div class="container">
        <md-dialog :md-active.sync="showDialogEditNiif" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
               <h4> {{ labels.title }} </h4>
            </md-dialog-title>
            <md-dialog-content>
                <b-row class="">
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.niif}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <h4> {{ Item.niif }} </h4>
                    </b-col>
                </b-row>
                <b-row class="" v-if="!showReclasifica">
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.description}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-input type="text" v-model="Item.descripcion" class="form-control" />
                    </b-col>
                </b-row>
                <b-row class="" v-if="showReclasifica">
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.description}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <h4> {{ Item.descripcion }} </h4>
                    </b-col>
                </b-row>
                <b-row class="" v-if="showReclasifica">
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.reclasificar}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-input type="text" v-model="Item.niif_nuevo" class="form-control" />
                    </b-col>
                </b-row>
                <b-row v-if="!showReclasifica">
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.levelFirst}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <select v-model="Item.primer_nivel" @change="onChange($event)" class="form-control">
                            <option value="">{{ labels.select }}</option>
                            <option v-for="firstLev in listFirstLevel" :key="firstLev.id" :value="firstLev.primer_nivel">{{ firstLev.descripcion }}</option>
                        </select>
                    </b-col>
                </b-row>
                <b-row v-if="!showReclasifica">
                    <b-col cols="1"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.levelSecond}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <select v-model="Item.segundo_nivel" class="form-control">
                            <option value="">{{ labels.select }}</option>
                            <option v-for="secondLev in listSecondLevelFirst" :key="secondLev.id" :value="secondLev.segundo_nivel">{{ secondLev.descripcion }}</option>
                        </select>
                    </b-col>
                </b-row>
                <md-dialog-actions class="mt-3">
                    <md-button v-if="!showReclasifica" class="md-primary" @click="reclasificarNiif">{{ labels.breclasificar }}</md-button>
                    <md-button class="md-primary" @click="editarNiif">{{ labels.save }}</md-button>
                    <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
                </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>
    
    import PlanConsolidacion from "../../services/PlanConsolidacion"

    export default {
        name:"EditaNiif",
        props:['Item','showDialogEditNiif','listFirstLevel','listSecondLevelFirst','listNiif'],

data: () => ({
	descripcion: "",
	idNiif: "",
	showReclasifica: false,

}),

        computed:{
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        title: "Modificacion Niif",
                        niif : "Niif:",
                        select : "Seleccione...",
                        levelFirst : "Primer nivel:",
                        levelSecond : "Segundo nivel:",
                        save : "Guardar",
                        breclasificar : "Reclasificar",
                        reclasificar : "Niif Nuevo",
                        close : "Cerrar",
                        description : "Descripción:"
                    }
                }else{
                    return {
                        title: "Niif Update",
                        niif : "Niif:",
                        select : "Select...",
                        levelFirst : "First level:",
                        levelSecond : "Second level:",
                        save : "Save",
                        breclasificar : "Reclassify",
                        reclasificar : "New Niif",
                        close : "Close",
                        description : "Description:"
                    }
                }
            }
        },

methods: {
	clicked(){
		this.showReclasifica=false
		this.$emit('clicked', false);
	},
	reload(){
		this.$emit('reload')
	},
	editarNiif(){

		if	(this.showReclasifica) {
			if	(this.Item.nuevo_niif == "") {
				this.$toast.error("Nuevo Niif no puede estar vacio")
				return
			}
			const nuevoNiif = this.listNiif.find((r_niif) =>  
				r_niif.niif == this.Item.niif_nuevo
			)
			if	(nuevoNiif) {
				this.$toast.error("Niif ya existe.("+nuevoNiif.nameNiif+")")
				this.Item.niif_nuevo = this.Item.niif
				return
			}
			let form = {
				'codReporte'	: this.Item.codReporte,
				'vigencia'	: this.Item.vigencia,
				'niif'		: this.Item.niif,
				'niif_nuevo'	: this.Item.niif_nuevo,
			}
			PlanConsolidacion.reclasificaNiif(form)
			.then((res) => {
				this.$toast.success(res.data.message)
				this.clicked()
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				this.reload()
			})
			return
		}
		if(this.Item.descripcion == ""){
			this.$toast.error("Diliganciar el campo Descripción")
		}else{
			let form = {
				'idNiif'        : this.Item.id,
				'descripcion'   : this.Item.descripcion,
				'primer_nivel'   : this.Item.primer_nivel,
				'segundo_nivel'   : this.Item.segundo_nivel
			}

			PlanConsolidacion.updatePlanconsolidacion(form)
			.then((res) => {
				this.$toast.success(res.data.message)
				this.clicked()
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				this.reload()
			})
		}                
	},
	reclasificarNiif(){
		this.showReclasifica=true
	},
},
mounted() {
	this.showReclasifica=false
},
onChange(event){
	let value = event.target.value

	this.Item.segundo_nivel = ""
	this.$emit('leaSegundoNivel', value);
},


}
</script>
<style src="../main.css"></style>
