<template>
  <div class="container">
    <md-dialog :md-active.sync="showEditMasivo" :md-click-outside-to-close="false">
        <md-dialog-title class="text-center">
            {{ labels.title }}
        </md-dialog-title>
        <md-dialog-content>
            <!-- <pre>
                {{ listadoCuentas }}
            </pre> -->
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.criterio}}</h5>
                </b-col>
                <b-col cols="1" lg="6" class="text-left">
                    <input type="text" v-model="critCuenta" v-on:input="buscaCuenta" placeholder="Criterio Cuenta..." class="form-control"><br>
                    <input type="text" v-model="critDescripcion" v-on:input="buscaDescripcion" placeholder="Criterio Descripcion..." class="form-control">
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.cuenta}}</h5>
                </b-col>
                <b-col cols="6" class="text-left">
                    <select multiple v-model="listCuentasSel" class="form-control">
                        <option v-for="cuenta in listCuentas" :value="cuenta.cuenta" :key="cuenta.id">{{ cuenta.cuenta }} {{ cuenta.descripcion }}</option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.niif}}</h5>
                </b-col>
                <b-col cols="6" lg="6" class="text-left">
                    <select v-model="niifSel" class="form-control">
                        <option v-for="planCons in listNiif" :key="planCons.id" :value="planCons.niif"> {{ planCons.niif }} - {{planCons.nameNiif }} </option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.esf}}</h5>
                </b-col>
                <b-col cols="6" lg="3" class="text-left">
                    <select v-model="esf" class="form-control">
                        <option value="1">{{ labels.yes }}</option>
                        <option value="0">{{ labels.not }}</option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.eri}}</h5>
                </b-col>
                <b-col cols="6" lg="3" class="text-left">
                    <select v-model="eri" class="form-control">
                        <option value="1">{{ labels.yes }}</option>
                        <option value="0">{{ labels.not }}</option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.efe}}</h5>
                </b-col>
                <b-col cols="6" lg="3" class="text-left">
                    <select v-model="efe" class="form-control">
                        <option value="1">{{ labels.yes }}</option>
                        <option value="0">{{ labels.not }}</option>
                    </select>
                </b-col>
            </b-row>
            <b-row class="">
                <b-col cols="2"></b-col>
                <b-col cols="4" class="text-left">
                    <h5>{{labels.ecp}}</h5>
                </b-col>
                <b-col cols="6" lg="3" class="text-left">
                    <select v-model="ecp" class="form-control">
                        <option value="1">{{ labels.yes }}</option>
                        <option value="0">{{ labels.not }}</option>
                    </select>
                </b-col>
            </b-row>
            <md-dialog-actions class="mt-3">
                <md-button class="md-primary" @click="editPlanCuenta">{{ labels.save }}</md-button>
                <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
            </md-dialog-actions>
        </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>

import PlanCuentas from '../../services/PlanCuentas';
import SegundoNivel from '../../services/SegundoNivel';

export default {
    name:"EditaVariasCuentas",
    props:['showEditMasivo','compVigSelect','listadoCuentas','listadoNiif'],
    data: () => ({
        listCuentasSel : [],
        firstLevSel : "",
        listFirstLevel : [],
        secondLevSel : "",
        listSecondLevel : [],
        niifSel : "",
        esf :   "",
        eri :   "",
        efe :   "",
        ecp :   "",
        critCuenta :   "",
        listCuentas : [],
        listNiif : [],
        critDescripcion : ""
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title : "Edita cuentas",
                    save : "Guardar",
                    close : "Cerrar",
                    levelFirst : "Primer nivel:",
                    levelSecond : "Segundo nivel:",
                    cuenta: "Cuentas:",
                    name: "Nombre:",
                    niif: "Niif:",
                    esf: "Esf:",
                    eri: "Eri:",
                    efe: "Efe:",
                    ecp: "Ecp:",
                    sel: "Seleccione...",
                    yes: "SI",
                    not: "NO",
                    criterio: "Criterio:"
                }
            }else{
                return {
                    title : "Edit accounts",
                    save : "Save",
                    close : "Close",
                    levelFirst : "Level first:",
                    levelSecond : "Level second:",
                    cuenta: "Accounts:",
                    name: "Name:",
                    niif: "Niif:",
                    esf: "Esf:",
                    eri: "Eri:",
                    efe: "Efe:",
                    ecp: "Ecp:",
                    sel: "Select...",
                    yes: "Yes",
                    not: "No",
                    criterio: "Criterion:"
                }
            }
        }
    },
    methods:{
        clicked(){
            this.$emit('clicked', false);
            this.clearCampos()
        },
        onChange(event){
            // console.log(event.target.value,"VVVV")
            let value = event.target.value
            // console.log(value)

            /*this.Item.segundo_nivel = ""*/
            SegundoNivel.getAllLevelSecondByLevelFirst(value)
            .then((res) => {
                // console.log(res)
                this.listSecondLevel = res.data.secondLevByFirstLev
            })
            .catch((err) => {
                console.log(err)
            })
        },
        editPlanCuenta(){
            // console.log("EDITA")
            let datos = {
                'listCuentas'   : this.listCuentasSel,
                'firstLevel'    : this.firstLevSel,
                'secondLevel'   : this.secondLevSel,
                'niif'          : this.niifSel,
                'esf'           : this.esf,
                'eri'           : this.eri,
                'efe'           : this.efe,
                'ecp'           : this.ecp,
                'vigencia'      : this.compVigSelect.vigencia,
                'companyId'     : this.compVigSelect.companyId,
                'codReporte'     : this.compVigSelect.codReporte
            }
            // console.log(datos)
            
            PlanCuentas.planCuentasMassiveUpdate(datos)
            .then((res) => {

                if(res.status == 200){
                    this.$toast.success(res.data.message)
                    setTimeout(() => {                        
                        this.reloadTbl()
                    }, 2000);
                }
            })
            .catch((err) => {
                console.log(err)
            })
        },
        reloadTbl(){
            this.$emit('reload',this.compVigSelect.vigencia,this.compVigSelect.companyId,this.compVigSelect.codReporte)
            this.clearCampos()
        },
        clearCampos(){
            this.listCuentasSel = []
            this.firstLevSel = ""
            this.secondLevSel = ""
            this.niifSel = ""
            this.esf = ""
            this.eri = ""
            this.efe = ""
            this.ecp = ""
        },
        buscaCuenta() {
            const criterio = "^"+this.critCuenta
            if	(this.critCuenta.length ==0) {
                this.listCuentas = this.listadoCuentas
            } else {
                this.listCuentas = this.listadoCuentas.filter(
                (cuenta) => cuenta.cuenta.match(criterio)>0)
            }
        },
        buscaDescripcion(){
            if	(this.critDescripcion.length == 0) {
                this.listCuentas = this.listadoCuentas
            } else {
                this.listCuentas = this.listadoCuentas.filter(
                (cuenta) => cuenta.descripcion.toUpperCase().indexOf(this.critDescripcion.toUpperCase())>=0)
            }
        }
    },
    watch:{
        showEditMasivo(val){
            if(val == true){
                this.listCuentas = this.listadoCuentas
		this.listNiif = this.listadoNiif
            }
        }
    }
}
</script>

<style>

</style>
